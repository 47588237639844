
<template>
  <div>
    <v-btn
      fab
      absolute
      right
      small
      color="success"
      :to="{ name: 'DocumentEdit', params: { docId: 0, catId: 0 } }"
      ><v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-breadcrumbs :items="[{ text: 'keine Dateien' }]"> </v-breadcrumbs>
    <v-card :loading="loading" class="mb-4">
      <v-card-title
        >Dokumente mit fehlender Datei
        <v-spacer />
        <v-chip>
          {{ items.size }}
        </v-chip>
      </v-card-title>
      <DocumentList :documents="items" />
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import DocumentList from './DocumentList.vue';
export default {
  components: { DocumentList },
  data() {
    return {
      items: [],
      search: '',
      loading: false,
    };
  },

  methods: {
    async fetchData() {
      this.loading = true;
      this.items = [];
      this.items = await this.apiList({
        resource: 'document/document',
        query: 'noFiles',
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.update) {
      this.fetchData();
    }
    next();
  },
};
</script>
